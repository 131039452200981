import { GetterTree, MutationTree } from 'vuex';

export const namespaced = true;

export type SnippetState = ReturnType<typeof state>;

export const state = () => ({
  data: {} as Record<string, number>,
  requestPromise: null as Promise<any> | null,
});

export const getters: GetterTree<SnippetState, any> = {
  data(state): Record<string, number> {
    return state.data;
  },
};

export const mutations: MutationTree<SnippetState> = {
  SAVE_DATA_FROM_STRAPI(state: SnippetState, data: any) {
    if (data.snippets) {
      state.data = data.snippets.reduce((result: Record<string, number>, item: any) => {
        if (item && item.currency) {
          result[item.currency] = Number(item.eurEquivalent);
        }
        return result;
      }, {} as Record<string, number>);
    }
  },
};
