import { createStore } from 'vuex';
import * as language from './abstract/language';
import * as strapi from './abstract/strapi';
import * as autochangeData from './autochangeData';
import * as country from './client/country';
import * as platform from './client/platform';
import * as stag from './client/stag';
import * as profileForm from './profileForm';
import * as regForm from './regForm';
import * as ssCountries from './softswiss/ssCountries';
import * as ssPlayer from './softswiss/ssPlayer';
import * as ssTournaments from './softswiss/ssTournaments';
import * as ssUpdateLocale from './softswiss/ssUpdateLocale';
import * as auth from './strapi/auth';
import * as bonuses from './strapi/bonuses';
import * as buttonTitles from './strapi/buttonTitles';
import * as components from './strapi/components';
import * as currencyMenu from './strapi/currencyMenu';
import * as landing from './strapi/landing';
import * as races from './strapi/races';
import * as snippet from './strapi/snippet';
import * as systemHosts from './strapi/systemHosts';

export default createStore({
  state: {} as RootState,
  mutations: {},
  actions: {},
  modules: {
    // abstract - transport modules and abstract modules for data load
    strapi,
    language,

    // client - data from browser and 3rd parti services
    stag,
    platform,
    country,

    // strapi - data from strapi
    landing,
    systemHosts,
    currencyMenu,
    races,
    bonuses,
    buttonTitles,
    components,
    snippet,
    auth,

    // legacy from strapi
    autochangeData,
    regForm,
    profileForm,

    // softswiss - data from SS
    ssCountries,
    ssPlayer,
    ssUpdateLocale,
    ssTournaments,
  },
  strict: process.env.NODE_ENV !== 'production',
});

// eslint-disable-next-line
export type RootState = {};
