import axios from 'axios';
import { ISsTournamentResponse } from '@/store/softswiss/ssTournaments';

export async function loadTournaments(lang: string): Promise<ISsTournamentResponse[] | null> {
  const response = await axios.get('/api/tournaments', {
    withCredentials: true,
    headers: {
      Accept: 'application/vnd.softswiss.v1+json',
      'accept-language': lang,
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });

  return response.data;
}
