export enum StrapiTemplatesEnum {
  'landing-templates.australia' = 'australiaPromo',
  'landing-templates.modern' = 'modernPromo',
  'landing-templates.katsubet' = 'katsubetPromo',
  'landing-templates.modern2' = 'modern2Promo',
  'landing-templates.modern_multi' = 'modernPromo',
  'landing-templates.modern2_multi' = 'modern2Promo',
  'landing-templates.modern2-square' = 'modern2SquarePromo',
  'landing-templates.modern2-lion' = 'modern2LionPromo',
  'landing-templates.bonus-hub' = 'bonusHubPromo',
}
