import type { GetterTree, ActionTree, MutationTree } from 'vuex';
import { loadPlayerInfo, updatePlayerGroups } from '@/services/ssPlayer';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';
import {
  ISsserGroupsUpdateRequestInterface,
  SsPlayerResponseInterface,
} from '~/types/softswiss/ssPlayer.response.interface';

export const namespaced = true;

export const state = () => ({
  playerInfo: {} as SsPlayerResponseInterface,
  status: LoadingStatusEnum.noData as LoadingStatusEnum,
});

export type SsPlayerState = ReturnType<typeof state>;

export interface SsPlayerGettersInterface {
  isPlayerAuthorised: boolean;
}

export const getters: GetterTree<SsPlayerState, any> = {
  isPlayerAuthorised(state: SsPlayerState): boolean {
    return !!state.playerInfo.id;
  },
};

export const mutations: MutationTree<SsPlayerState> = {
  SAVE_PLAYER(state: SsPlayerState, playerInfo: SsPlayerResponseInterface) {
    state.playerInfo = { ...state.playerInfo, ...playerInfo };
  },
  SET_PLAYER_INFO_STATUS(state: SsPlayerState, status: LoadingStatusEnum) {
    state.status = status;
  },
};

export const actions: ActionTree<SsPlayerState, any> = {
  async loadPlayerInfo(ctx) {
    ctx.commit('SET_PLAYER_INFO_STATUS', LoadingStatusEnum.loading);

    try {
      const lang = ctx.rootGetters['language/language'];
      const playerInfo = await loadPlayerInfo(lang);

      ctx.commit('SAVE_PLAYER', playerInfo);
      ctx.commit('SET_PLAYER_INFO_STATUS', LoadingStatusEnum.loaded);
    } catch (e) {
      ctx.commit('SET_PLAYER_INFO_STATUS', LoadingStatusEnum.error);
    }
  },
  async updatePlayerGroups(ctx, body: ISsserGroupsUpdateRequestInterface): Promise<void> {
    try {
      const lang = ctx.rootGetters['language/language'];
      const groups: ISsserGroupsUpdateRequestInterface = {};

      if (body.add?.length) {
        groups.add = body.add;
      }

      if (body.remove?.length) {
        groups.remove = body.remove;
      }

      await updatePlayerGroups(groups, lang);
      await ctx.dispatch('loadPlayerInfo');
    } catch (e: any) {
      console.error(new Error('update of groups failed!'));
    }
  },
};
