import { computed } from 'vue';
import { useStore } from 'vuex';

export function useCountryStore() {
  const store = useStore();

  const country = computed((): string => store.getters['country/country']);

  const check = () => store.dispatch('country/check');

  return {
    country,
    check,
  };
}
