import { computed } from 'vue';
import { useStore } from 'vuex';
import { LangsType } from '@/types/pages.types';

export function useLanguageStore() {
  const store = useStore();

  const language = computed((): LangsType => store.getters['language/language']);

  const langPrefix = computed((): LangsType | '' => store.getters['language/langPrefix']);

  const setLocaleByCountry = () => store.dispatch('language/setLocaleByCountry');

  return {
    language,
    langPrefix,
    setLocaleByCountry,
  };
}
