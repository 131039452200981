import { ActionTree } from 'vuex';
import { updateLocale } from '@/services/updateLocale';

export const namespaced = true;

export const actions: ActionTree<any, any> = {
  async patch(ctx: { state: any; dispatch: any; commit: any; getters: any; rootGetters: any }) {
    try {
      const lang = ctx.rootGetters['language/language'];
      updateLocale(lang);
    } catch (e) {
      console.error('locale update network error');
    }
  },
};
