import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import { createGtm } from '@gtm-support/vue-gtm';
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/styles/main.scss';
import { gtmByHost } from './utils/gtmByHost';

const app = createApp(App);

app.use(store);

app.use(router);

app.use(
  createGtm({
    id: gtmByHost(location.host),
    defer: false,
    compatibility: false,
    enabled: true,
    debug: false,
    loadScript: true,
    trackOnNextTick: false,
  })
);

Bugsnag.start({
  apiKey: '443e0a6081ecbf6085a1a072a45a901b',
  plugins: [new BugsnagPluginVue()],
  releaseStage: process.env.NODE_ENV,
  enabledReleaseStages: ['staging', 'production'],
  appVersion: 'v1.0.0',
});

const bugsnagVue = Bugsnag.getPlugin('vue');

app.use(bugsnagVue);

app.mount('#app');
