import { computed } from 'vue';
import { useStore } from 'vuex';
import { CurrencyMenuGettersInterface } from '@/store/strapi/currencyMenu';
import { LandingGettersInterface } from '@/store/strapi/landing';

export function useStrapiStore() {
  const store = useStore();

  const redirectHost = computed(() => store.getters['systemHosts/redirectHost']);

  const ssHost = computed(() => store.getters['systemHosts/ssHost']);

  const pathes = computed((): LandingGettersInterface['pathes'] => store.getters['landing/pathes']);

  const pageData = computed(
    (): LandingGettersInterface['pageData'] => store.getters['landing/pageData']
  );

  const isLoaded = computed(
    (): LandingGettersInterface['isLoaded'] => store.getters['landing/isLoaded']
  );

  const currencies = computed(
    (): CurrencyMenuGettersInterface['currencies'] => store.getters['currencyMenu/currencies']
  );

  const loadSystemHostsData = () => store.dispatch('strapi/loadSystemHostsData');

  const loadLandingsData = (path: string) => store.dispatch('strapi/loadLandingsData', path);

  const loadBonusesAndRacesData = () => store.dispatch('strapi/loadBonusesAndRacesData');

  const loadCommonData = () => store.dispatch('strapi/loadCommonData');

  return {
    redirectHost,
    ssHost,
    pathes,
    pageData,
    isLoaded,
    currencies,
    loadSystemHostsData,
    loadLandingsData,
    loadBonusesAndRacesData,
    loadCommonData,
  };
}
