import { GetterTree, MutationTree } from 'vuex';

export const namespaced = true;

export type ButtonTitlesState = ReturnType<typeof state>;

export interface ButtonTitlesGettersInterface {
  buttons: ButtonTitlesState;
}

export const state = () => ({
  signup: '',
  learnMore: '',
  requestPromise: null as Promise<any> | null,
});

export const getters: GetterTree<ButtonTitlesState, any> = {
  buttons(state: ButtonTitlesState): ButtonTitlesState {
    return state;
  },
};

export const mutations: MutationTree<ButtonTitlesState> = {
  SAVE_DATA_FROM_STRAPI(state: ButtonTitlesState, { data }) {
    state.signup = data.signup;
    state.learnMore = data.learnMore;
  },
};
