<template>
  <div class="promopage">
    <component :is="promoComponent" />
  </div>
</template>

<script setup lang="ts">
import Bugsnag from '@bugsnag/js';
import { load as loadRecaptcha } from 'recaptcha-v3';
import { computed, defineAsyncComponent, onBeforeMount, provide, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { RECAPTCCHA_SITEKEYS } from '../config/constants';
import { useCommonStore } from '@/composables/useCommonStore';
import { useCountryStore } from '@/composables/useCountryStore';
import { useLandingComputed } from '@/composables/useLandingComputed';
import { useLanguageStore } from '@/composables/useLanguageStore';
import { useStrapiStore } from '@/composables/useStrapiStore';
import { StrapiTemplatesEnum } from '@/types/strapiTemplateEnum';

const store = useStore();
const strapi = useStrapiStore();
const common = useCommonStore();
const country = useCountryStore();
const language = useLanguageStore();
const landing = useLandingComputed();

const isReady = ref(false);

const titleData: {
  titles: string[];
  index: number;
} = reactive({
  titles: [],
  index: 0,
});

let intervalId: number;

const promoComponent = computed(() => {
  if (landing.currentPage.value?.template?.__component) {
    const template = StrapiTemplatesEnum[landing.currentPage.value.template.__component];

    if (template) {
      return defineAsyncComponent(() => import(`@/components/promoPage/${template}.vue`));
    }
  }

  return null;
});

const isPlayerAuthorised = computed((): boolean => store.getters['ssPlayer/isPlayerAuthorised']);

const startTitleBleems = (): void => {
  if (intervalId) {
    window.clearInterval(intervalId);
  }

  const title = landing.currentPage.value?.head?.title || '';

  if (title.split('|').length > 1) {
    titleData.titles = title.split('|') || [''];
    titleData.index = 0;
  } else {
    document.title = title;
  }

  intervalId = window.setInterval(() => {
    if (titleData.index >= titleData.titles.length - 1) {
      titleData.index = 0;
    } else {
      titleData.index++;
    }
  }, 1000);
};

watch(
  () => titleData.index,
  (i) => {
    document.title = titleData.titles[i];
  }
);

const changeFavicon = (url: string): void => {
  let link: HTMLLinkElement = document.head.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
  link.href = url;
};

const setFavicon = (): void => {
  if (strapi.isLoaded.value && landing.currentPage.value.template?.favicon) {
    changeFavicon(landing.currentPage.value.template?.favicon.data.attributes.url);
  } else {
    changeFavicon('/favicon-7bit.ico');
  }
};

const setDescription = (): void => {
  document.getElementsByTagName('meta')['description'].content =
    landing.currentPage.value?.head.description || '';
};

const recaptchaKey = Object.keys(RECAPTCCHA_SITEKEYS).find((key) => location.host.includes(key));
provide('recaptchaKey', recaptchaKey);

const recaptcha = loadRecaptcha(RECAPTCCHA_SITEKEYS[recaptchaKey], {
  renderParameters: { hl: 'en' },
});

provide('recaptcha', recaptcha);

onBeforeMount(async () => {
  await strapi.loadSystemHostsData();
  await country.check();
  language.setLocaleByCountry();
  store.dispatch('ssUpdateLocale/patch');
  await strapi.loadLandingsData(common.path.value);
  await Promise.all([
    store.dispatch('autochangeData/load'),
    store.dispatch('ssPlayer/loadPlayerInfo'),
  ]);

  if (strapi.pathes.value.length === 0) {
    window.location.href = `https://${strapi.redirectHost.value}`;
    return;
  }

  // if promo page doesnt exist - redirect to casino
  if (
    strapi.pathes.value.length &&
    common.path.value &&
    !strapi.pathes.value.includes(common.path.value)
  ) {
    window.location.href = `https://${strapi.redirectHost.value}`;
    return;
  }

  // for landing template modern australia katsubet if player authorize then redirect to main site. For modern2 another logic
  if (
    isPlayerAuthorised.value &&
    StrapiTemplatesEnum[landing.currentPage.value.template.__component] !== 'modern2Promo'
  ) {
    window.location.href = `https://${strapi.redirectHost.value}`;
    return;
  }

  store.dispatch('regForm/load', landing.lang.value);
  store.dispatch('stag/checkStag');
  store.dispatch('stag/saveCurrentStag', { lang: landing.lang.value });
  store.dispatch('profileForm/load', landing.lang.value);

  isReady.value = true;

  startTitleBleems();
  setFavicon();
  setDescription();

  if (!landing.currentPage.value.template.__component) {
    Bugsnag.notify(`No such promo page [ ${common.path.value} ] !`);
  }
});
</script>

<style lang="scss">
#promo {
  margin: 0;
}

.grecaptcha-badge,
.grecaptcha-logo {
  opacity: 0 !important;
}

#onesignal-slidedown-container {
  display: none;
}
</style>
