import { GetterTree, MutationTree } from 'vuex';
import { DEFAULT_REDIRECT_HOST } from '@/config/constants';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';
import { SystemHostsStrapiInterface } from '~/types/strapi/systemHosts.strapi.interface';

export const namespaced = true;

export const state = () => ({
  hosts: [] as SystemHostsStrapiInterface[],
  status: LoadingStatusEnum.noData as LoadingStatusEnum,
});

export type SystemHostsState = ReturnType<typeof state>;

export interface SystemHostsGetters {
  regional: string;
  geo: string;
  redirect: string;
  gameImages: string;
  currency: string;
  list: SystemHostsStrapiInterface[];
  current: SystemHostsStrapiInterface | null;
  isCurentHostPromo: boolean;
  isCurentHostCasino: boolean;
  ssHost: string;
  isLoaded: boolean;
}

export const getters: GetterTree<SystemHostsState, any> = {
  /**
   * regional domain host
   * @param state of store
   */
  regional(state: SystemHostsState): string {
    const defaultHost = '7bitcasino.com';

    if (window?.location?.host && state.status === LoadingStatusEnum.loaded) {
      return (
        state.hosts.find((h) => h.host === window.location.host)?.regionalVisibleDomain ||
        defaultHost
      );
    }

    return defaultHost;
  },

  /**
   * geoip host
   * @param state of store
   */
  geo(_state: SystemHostsState, getters: SystemHostsGetters): string {
    const defaultHost = 'country.7bitcasino13.com';

    if (getters.current) {
      return getters.current?.geoHost || defaultHost;
    }

    return defaultHost;
  },

  /**
   * redirect host
   * @param state of store
   */
  redirectHost(
    _state: SystemHostsState,
    getters: SystemHostsGetters,
    _rootState,
    rootGetters
  ): string {
    const langPrefix = rootGetters['language/langPrefix'];
    let redirectHost = DEFAULT_REDIRECT_HOST;

    if (getters.current) {
      redirectHost = getters.current?.redirectHost;
    }

    return `${redirectHost}${langPrefix}`;
  },
  /**
   * images host
   * @param state of store
   */
  gameImages(_state: SystemHostsState, getters: SystemHostsGetters): string {
    const defaultHost = '7bitcdn.com';

    if (getters.current) {
      return getters.current?.gameImagesHost || defaultHost;
    }

    return defaultHost;
  },

  /**
   * CMS host
   * @param state of store
   */
  cms(_state: SystemHostsState, getters: SystemHostsGetters): string {
    const defaultHost = 'dinamic-content.7bitcasino.com';

    if (getters.current) {
      return getters.current?.cmsHost || defaultHost;
    }

    return defaultHost;
  },

  /**
   * list of all hosts
   * @param state of store
   */
  list(state: SystemHostsState): SystemHostsStrapiInterface[] {
    return state.hosts;
  },

  /**
   * current host params
   * @param state of store
   */
  current(state: SystemHostsState): SystemHostsStrapiInterface | null {
    return state.hosts.find((h) => h?.host.toLowerCase() === window?.location?.host) || null;
  },

  ssHost(_state: SystemHostsState, getters: SystemHostsGetters): string {
    return getters.current?.ssHost;
  },

  isCurentHostPromo(_state: SystemHostsState, getters: SystemHostsGetters): boolean {
    return getters.current?.hostType === 'promo';
  },

  isCurentHostCasino(_state: SystemHostsState, getters: SystemHostsGetters): boolean {
    return getters.current?.hostType === 'casino';
  },

  /**
   * status of loading
   * @param state of store
   */
  isLoaded(state: SystemHostsState): boolean {
    return state.status === LoadingStatusEnum.loaded;
  },
};

export const mutations: MutationTree<SystemHostsState> = {
  SAVE_HOSTS_FROM_STRAPI(state: SystemHostsState, data: SystemHostsStrapiInterface[]) {
    state.hosts = data.map((item) => ({
      cmsHost: item.cmsHost,
      gameImagesHost: item.gameImagesHost,
      geoHost: item.geoHost,
      host: item.host,
      hostType: item.hostType,
      isStage: item.isStage,
      redirectHost: item.redirectHost,
      regionalVisibleDomain: item.regionalVisibleDomain,
      ssHost: item.ssHost,
    }));
  },
  SET_STATUS(state: SystemHostsState, status: LoadingStatusEnum) {
    state.status = status;
  },
};
