import { GetterTree, MutationTree } from 'vuex';

export const namespaced = true;

export type ComponentsState = ReturnType<typeof state>;

export interface AuthDataInterface {
  promoTooltipText: string;
  crossSaleTitle: string;
  signUpGoogleTitle: string;
  dividerTitle: string;
}

export const state = () => ({
  promoTooltipText: '',
  crossSaleTitle: '',
  signUpGoogleTitle: '',
  dividerTitle: '',
  requestPromise: null as Promise<any> | null,
});

export const getters: GetterTree<AuthDataInterface, any> = {
  auth(state: AuthDataInterface): AuthDataInterface {
    return state;
  },
};

export const mutations: MutationTree<ComponentsState> = {
  SAVE_DATA_FROM_STRAPI(state: ComponentsState, itemAuth: any) {
    state.promoTooltipText = itemAuth.data.promoTooltipText;
    state.crossSaleTitle = itemAuth.data.crossSaleTitle;
    state.signUpGoogleTitle = itemAuth.data.signUpGoogleTitle;
    state.dividerTitle = itemAuth.data.dividerTitle;
  },
};
