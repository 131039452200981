import Bugsnag from '@bugsnag/js';
import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { getCountry } from '@/services/country';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';

export const namespaced = true;

export const state = () => ({
  country: '',
  status: LoadingStatusEnum.noData as LoadingStatusEnum,
});

export type CountryState = ReturnType<typeof state>;

export const getters: GetterTree<CountryState, any> = {
  country(state): string {
    return state.country || '';
  },
  isLoaded(state): boolean {
    return state.status === LoadingStatusEnum.loaded;
  },
};

export const mutations: MutationTree<CountryState> = {
  SAVE_COUNTRY(state, country: string) {
    state.country = String(country).toUpperCase();
  },
  SET_STATUS(state: CountryState, status: LoadingStatusEnum) {
    state.status = status;
  },
};

export const actions: ActionTree<CountryState, any> = {
  async check({ commit, state, rootGetters }: any) {
    try {
      if (state.status === LoadingStatusEnum.loaded) {
        return;
      }

      commit('SET_STATUS', LoadingStatusEnum.loading);

      const host = `${location.host}/cf-api/country`;
      const country = await getCountry(host);

      if (country) {
        commit('SAVE_COUNTRY', country);
        commit('SET_STATUS', LoadingStatusEnum.loaded);
      } else {
        commit('SET_STATUS', LoadingStatusEnum.noData);
      }
    } catch (e) {
      commit('SET_STATUS', LoadingStatusEnum.error);

      Bugsnag.notify(e, function (event) {
        event.addMetadata('Metadata', { store: 'geoip', method: 'load' });
      });
    }
  },
};
