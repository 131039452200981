import axios from 'axios';
import { ESeonStatus, ISeonResponse } from '@/types/seon';
import { ISsserGroupsUpdateRequestInterface } from '@/types/softswiss/ssPlayer.response.interface';

export async function loadPlayerInfo(lang: string) {
  const response = await axios.get('/api/player', {
    withCredentials: true,
    headers: {
      Accept: 'application/vnd.softswiss.v1+json',
      'accept-language': lang,
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });

  return response.data;
}

export async function updatePlayerGroups(groups: ISsserGroupsUpdateRequestInterface, lang: string) {
  const response = await axios.post(
    '/api/player/groups',
    { groups },
    {
      withCredentials: true,
      headers: {
        Accept: 'application/vnd.softswiss.v1+json',
        'accept-language': lang,
        'Content-Type': 'application/json;charset=UTF-8',
      },
    }
  );

  return response.data;
}

export async function checkIfFraudEmailWithSeon(email: string) {
  try {
    // @ts-ignore
    const session: string = await window.seon.getBase64Session();
    const response: ISeonResponse = await axios.post('/seon', {
      email,
      session,
    });

    return response.data.data.state === ESeonStatus.decline;
  } catch (e) {
    console.info(e);

    return false;
  }
}
