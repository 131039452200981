import axios from 'axios';
import { SsCountriesResponseInterface } from '@/types/softswiss/ssCountriesResponse.interface';

export async function getCountries() {
  const response = await axios.get<SsCountriesResponseInterface[]>('/api/info/countries', {
    withCredentials: true,
    headers: {
      Accept: 'application/vnd.softswiss.v1+json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  });

  return response.data;
}
