import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { DEFAULT_LOCALE } from '@/config/constants';
import { LangsEnum, LangsType } from '@/types/pages.types';

export const namespaced = true;

export const state = () => ({
  language: DEFAULT_LOCALE as LangsType,
});

export type LanguageState = ReturnType<typeof state>;

export const getters: GetterTree<LanguageState, any> = {
  language(state): LangsType {
    return state.language;
  },
  langPrefix(state): string {
    return state.language !== DEFAULT_LOCALE ? `/${state.language}` : '';
  },
};

export const mutations: MutationTree<LanguageState> = {
  SET_LOCALE(state, locale: LangsType) {
    state.language = locale;
  },
};

export const actions: ActionTree<LanguageState, any> = {
  setLocale({ commit }, locale: LangsType) {
    commit('SET_LOCALE', locale);
  },
  setLocaleByCountry({ dispatch, rootGetters }: any) {
    const country = rootGetters['country/country'];

    if (country) {
      const localeMatch = LangsEnum[country.toLowerCase()];

      if (localeMatch) {
        dispatch('setLocale', localeMatch);
      }
    }
  },
};
