import { GetterTree, ActionTree, MutationTree } from 'vuex';
import { getCountries } from '@/services/ssCountries';
import { LoadingStatusEnum } from '~/types/loadingStatus.enum';
import { SsCountriesResponseInterface } from '~/types/softswiss/ssCountriesResponse.interface';

export const namespaced = true;

export const state = () => ({
  countries: [] as SsCountriesResponseInterface[],
  status: LoadingStatusEnum.noData as LoadingStatusEnum,
});

export type SsCountriesState = ReturnType<typeof state>;

export const getters: GetterTree<SsCountriesState, any> = {
  countries(state: SsCountriesState): SsCountriesResponseInterface[] {
    return state.countries;
  },
  isCountriesLoaded(state: SsCountriesState): boolean {
    return state.status === LoadingStatusEnum.loaded;
  },
};

export const mutations: MutationTree<SsCountriesState> = {
  SAVE_COUNTRIES(state, countries: SsCountriesResponseInterface[]) {
    state.countries = countries;
  },
  SET_STATUS(state: SsCountriesState, status: LoadingStatusEnum) {
    state.status = status;
  },
};

export const actions: ActionTree<SsCountriesState, any> = {
  async load({ getters, commit }: any): Promise<SsCountriesResponseInterface[] | null> {
    try {
      if (getters.isCountriesLoaded) {
        return getters.countries;
      }

      commit('SET_STATUS', LoadingStatusEnum.loading);

      const countries = await getCountries();

      if (countries) {
        commit('SAVE_COUNTRIES', countries);
      }

      commit('SET_STATUS', LoadingStatusEnum.loaded);

      return getters.countries;
    } catch (e) {
      commit('SET_STATUS', LoadingStatusEnum.error);

      return null;
    }
  },
};
