import type { ActionTree } from 'vuex';
import { RootState } from '~/store';
import { strapi } from '~/types/transport/strapi';

export const namespaced = true;

export const state = () => ({});

export type PagesState = ReturnType<typeof state>;

export const actions: ActionTree<PagesState, RootState> = {
  async loadSystemHostsData(ctx): Promise<boolean> {
    const locale = ctx.rootGetters['language/language'] as string;
    const response = await strapi.get('system-hosts', `locale=${locale}&pagination[pageSize]=1000`);

    if (response) {
      ctx.commit('systemHosts/SAVE_HOSTS_FROM_STRAPI', response.data, { root: true });

      return true;
    } else {
      return false;
    }
  },

  async loadLandingsData(ctx, path): Promise<boolean> {
    try {
      const locale = ctx.rootGetters['language/language'] as string;
      const [landingsResponse, currencyMenusResponse] = await Promise.all([
        strapi.get(
          'landings',
          `locale=${locale}&populate[0]=head&populate[1]=filterCountries&populate[2]=filterStags&populate[3]=template.paymentsLogo&populate[4]=template.partnersLogo&populate[5]=template.partnersLogoTop&populate[6]=template.logo&populate[7]=template.bannerDesktop&populate[8]=template.bannerMobile&populate[9]=template.modalBannerDesktop&populate[10]=template.modalBannerMobile&populate[11]=template.currency&populate[12]=template.welcomePackBlock&populate[13]=template.welcomePackBlock.cardIcon&populate[14]=template.promotionsBlock&populate[15]=template.tournamentsBlock&filters[path][$eq]=${path}`
        ),
        strapi.get('currency-menus', '?populate=*'),
      ]);

      ctx.dispatch('language/setLocale', locale, {
        root: true,
      });

      if (landingsResponse && currencyMenusResponse) {
        ctx.commit('landing/ADD_PAGES_FROM_STRAPI', landingsResponse.data, {
          root: true,
        });
        ctx.commit('currencyMenu/SAVE_CURRENCIES_FROM_STRAPI', currencyMenusResponse.data, {
          root: true,
        });

        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error loading landing data:', error);
      return false;
    }
  },

  async loadBonusesAndRacesData(ctx): Promise<boolean> {
    try {
      const locale = ctx.rootGetters['language/language'] as string;

      // Perform all three API requests
      const [bonusesResponse, racesResponse, bonusesTabsResponse] = await Promise.all([
        strapi.get(
          'bonuses',
          `locale=${locale}&populate[0]=bonusType&populate[1]=bannerCardFront&populate[2]=bannerCardBack`
        ),
        strapi.get(
          'races',
          `locale=${locale}&populate[0]=bannerCardBack&populate[1]=bannerCardFront&populate[2]=bannerSmallMobile&populate[3]=bannerSmall`
        ),
        strapi.get('bonuses-tabs', `locale=${locale}`),
      ]);

      if (bonusesResponse && racesResponse && bonusesTabsResponse) {
        ctx.commit(
          'bonuses/SAVE_DATA_FROM_STRAPI',
          { ...bonusesResponse, bonusesTabs: bonusesTabsResponse.data },
          { root: true }
        );
        ctx.commit('races/SAVE_DATA_FROM_STRAPI', racesResponse.data, { root: true });

        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error loading bonuses and races data:', error);
      return false;
    }
  },

  async loadCommonData(ctx): Promise<boolean> {
    try {
      const locale = ctx.rootGetters['language/language'] as string;
      const [componentResponse, buttonTitleResponse, snippetResponse, authModalResponse] =
        await Promise.all([
          strapi.get(
            'component',
            `locale=${locale}&populate[races][fields][0]=raceLabelWeeklyTitle&populate[races][fields][1]=raceLabelDailyTitle&populate[races][fields][2]=raceLabelExclusiveTitle&populate[races][fields][3]=raceLabelPartnerTitle&populate[timer][fields][0]=leftTitle&populate[timer][fields][1]=daysTitle&populate[timer][fields][2]=hoursTitle&populate[timer][fields][3]=minutesTitle&populate[timer][fields][4]=secondsTitle`
          ),
          strapi.get('button-title', `locale=${locale}&fields[0]=signup&fields[1]=learnMore`),
          strapi.get('snippet'),
          strapi.get('auth-modal', `locale=${locale}&populate=*`),
        ]);

      if (componentResponse && buttonTitleResponse && snippetResponse && authModalResponse) {
        ctx.commit('components/SAVE_DATA_FROM_STRAPI', componentResponse, { root: true });
        ctx.commit('buttonTitles/SAVE_DATA_FROM_STRAPI', buttonTitleResponse, { root: true });
        ctx.commit('snippet/SAVE_DATA_FROM_STRAPI', snippetResponse.data, { root: true });
        ctx.commit('auth/SAVE_DATA_FROM_STRAPI', authModalResponse, { root: true });

        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error loading common data:', error);
      return false;
    }
  },
};
