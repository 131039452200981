<template>
  <div id="promo">
    <router-view />
  </div>
</template>

<style lang="scss">
@font-face {
  font-display: swap;
  font-family: 'BlissPro';
  font-weight: 300;
  src: url('~assets/fonts/BlissPro-Light.eot');
  src: url('~assets/fonts/BlissPro-Light.woff') format('woff');
  src: url('~assets/fonts/BlissPro-Light.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'BlissPro';
  font-weight: 400;
  src: url('~assets/fonts/BlissPro.eot');
  src: url('~assets/fonts/BlissPro.woff') format('woff');
  src: url('~assets/fonts/BlissPro.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'BlissPro';
  font-weight: 500;
  src: url('~assets/fonts/BlissPro-Medium.eot');
  src: url('~assets/fonts/BlissPro-Medium.woff') format('woff');
  src: url('~assets/fonts/BlissPro-Medium.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'BlissPro';
  font-weight: 800;
  src: url('~assets/fonts/BlissPro-ExtraBold.eot');
  src: url('~assets/fonts/BlissPro-ExtraBold.woff') format('woff');
  src: url('~assets/fonts/BlissPro-ExtraBold.ttf') format('truetype');
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 3px;
  }
  &::-webkit-scrollbar-track {
    background-color: $color-indifferent;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb {
    height: 60px;
    background-color: $color-active-border;
    border: 1px solid $color-7bit-accent;
    border-radius: 50px;
  }
}

body.stop-scroll {
  overflow: hidden;
  width: 100%;
  .page-level-one {
    pointer-events: none;
  }
}
</style>

<script lang="ts" setup>
import { v4 as uuid } from 'uuid';
import { computed, onBeforeMount, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import usePromoModal from './composables/promoModalState';
import { useCookieUserType } from './composables/useCookieUserType';
import { useOneSignalID } from './composables/useOneSignalID';
import { useOriginalStag } from './composables/useOriginalStag';
import { useStrapiStore } from './composables/useStrapiStore';
import { scriptLoader } from '~/utils/scriptLoader';

const store = useStore();
store.dispatch('platform/checkPlatform');

const isDesktop = computed(() => store.getters['platform/isDesktop']);
const isMobile = computed(() => store.getters['platform/isMobile']);
const isTablet = computed(() => store.getters['platform/isTablet']);
const isPortraitOrientation = computed(() => store.getters['platform/isPortraitOrientation']);

const { show } = usePromoModal();
const { loadCommonData } = useStrapiStore();

const getBodyClass = computed(() => {
  const bodyClass: string[] = [];

  if (isDesktop.value) {
    bodyClass.push('isDesktop');
  } else if (isTablet.value) {
    bodyClass.push('isTablet');
  } else if (isMobile.value) {
    bodyClass.push('isMobile');
  }

  if (show.value) {
    bodyClass.push('stop-scroll');
  }

  bodyClass.push(isPortraitOrientation.value ? 'isPortrait' : 'isAlbum');

  return bodyClass.join(' ');
});

watch(
  getBodyClass,
  (classes) => {
    const classesArr = [
      'isDesktop',
      'isTablet',
      'isMobile',
      'isPortrait',
      'isAlbum',
      'stop-scroll',
    ];
    classesArr.forEach((cl) => {
      document.body.classList.remove(cl);
    });
    classes.split(' ').forEach((cl) => {
      if (!document.body.classList.contains(cl)) {
        document.body.classList.add(cl);
      }
    });
  },
  { immediate: true }
);

onBeforeMount(() => {
  useCookieUserType().init();
  useOriginalStag().init();
  loadCommonData();
  useOneSignalID().init();
});

onMounted(async () => {
  await scriptLoader('https://cdn.seondf.com/js/v5/agent.js', 'seondf');
  // @ts-ignore
  window.seon.config({
    host: 'seondf.com',
    session_id: uuid(),
    audio_fingerprint: true,
    canvas_fingerprint: true,
    webgl_fingerprint: true,
    max_proxy_delay: 1000,
  });
});
</script>
