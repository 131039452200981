import * as DeviceDetect from 'mobile-device-detect';
import { LoadingStatusEnum } from '../../types/loadingStatus.enum';
import { PlatformTypeEnum } from '../../types/platformTypes.enum';

export const namespaced = true;

interface PlatformState {
  widthMax: {
    mobile: number;
    tablet: number;
  };
  screen: { width: number; height: number };
  platform: PlatformTypeEnum;
  status: LoadingStatusEnum;
}

export const state = (): PlatformState => ({
  widthMax: {
    mobile: 700,
    tablet: 975,
  },
  screen: { width: NaN, height: NaN },
  platform: PlatformTypeEnum.unknown as PlatformTypeEnum,
  status: LoadingStatusEnum.noData as LoadingStatusEnum,
});

export interface PlatformGetters {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
  isPortraitOrientation: boolean;
  platform: PlatformTypeEnum;
  isLoaded: boolean;
  screenWidth: number;
  screenHeight: number;
}

export const getters = {
  /**
   * Method for getting platform status if check mobile device
   **/
  isMobile(_state: PlatformState, getters: PlatformGetters): boolean {
    return getters.platform === PlatformTypeEnum.mobile;
  },

  /**
   * Method for getting platform status if check tablet device
   **/
  isTablet(_state: PlatformState, getters: PlatformGetters): boolean {
    return getters.platform === PlatformTypeEnum.tablet;
  },

  /**
   * Method for getting platform status if check desktop device
   **/
  isDesktop(_state: PlatformState, getters: PlatformGetters): boolean {
    return getters.platform === PlatformTypeEnum.desktop;
  },

  /**
   * Method for getting platform status if check desktop device
   **/
  platform(state: PlatformState): PlatformTypeEnum {
    if (state.platform === PlatformTypeEnum.mobile || state.screen.width <= state.widthMax.mobile) {
      return PlatformTypeEnum.mobile;
    } else if (
      state.platform === PlatformTypeEnum.tablet ||
      (state.screen.width >= state.widthMax.mobile && state.screen.width <= state.widthMax.tablet)
    ) {
      return PlatformTypeEnum.tablet;
    } else if (state.platform === PlatformTypeEnum.desktop && state.screen.width > 975) {
      return PlatformTypeEnum.desktop;
    }

    return PlatformTypeEnum.unknown;
  },

  /**
   * Method for getting orientation of device
   **/
  isPortraitOrientation(state: PlatformState): boolean {
    return state.screen.width > state.screen.height;
  },

  isLoaded(state: PlatformState): boolean {
    return state.status === LoadingStatusEnum.loaded;
  },

  screenWidth(state: PlatformState): number {
    return state.screen.width;
  },

  screenHeight(state: PlatformState): number {
    return state.screen.height;
  },
};

export const mutations = {
  SET_PLATFORM(state: PlatformState, platform: PlatformTypeEnum): void {
    if (state.platform !== platform) {
      state.platform = platform;
    }
  },
  SET_STATUS(state: PlatformState, status: LoadingStatusEnum): void {
    if (state.status !== status) {
      state.status = status;
    }
  },
  SET_SCREEN_SIZE(
    state: PlatformState,
    size: {
      width: number;
      height: number;
    }
  ): void {
    state.screen.width = size.width;
    state.screen.height = size.height;
  },
};

let resizeListener = false;
let resizeInterval: number;
let needResizeCheck = true;
let platformChecked = false;

export const actions = {
  /**
   * function for set platform data to the state from plugin 'mobile-device-detect'
   * Plugin documentation: https://github.com/duskload/mobile-device-detect/blob/master/README.md
   **/
  async checkPlatform({ commit, dispatch }: any, force?: boolean): Promise<any> {
    if (platformChecked && !force) {
      return;
    }

    platformChecked = true;

    await dispatch('checkScreenSize');

    if (DeviceDetect.isMobileOnly) {
      commit('SET_PLATFORM', PlatformTypeEnum.mobile);
      commit('SET_STATUS', LoadingStatusEnum.loaded);
    } else if (DeviceDetect.isTablet) {
      commit('SET_PLATFORM', PlatformTypeEnum.tablet);
      commit('SET_STATUS', LoadingStatusEnum.loaded);
    } else {
      commit('SET_PLATFORM', PlatformTypeEnum.desktop);
      commit('SET_STATUS', LoadingStatusEnum.loaded);
    }
  },

  checkScreenSize({ commit, dispatch }: any) {
    needResizeCheck = false;

    commit('SET_SCREEN_SIZE', {
      width: window.innerWidth,
      height: window.innerHeight,
    });

    if (!resizeListener) {
      resizeListener = true;

      clearInterval(resizeInterval);
      resizeInterval = window.setInterval(() => {
        if (needResizeCheck) {
          dispatch('checkScreenSize');
        }
      }, 100);

      window.addEventListener('resize', () => (needResizeCheck = true));
    }
  },
};
