import type { ActionTree, GetterTree, MutationTree } from 'vuex';
import { loadTournaments } from '@/services/ssTournaments';
import { filterDateActiveItem } from '@/utils/filters';

export const namespaced = true;

export type SsTournamentsState = ReturnType<typeof state>;

export interface SsTournamentsGettersInterface {
  activeTournaments: ISsTournamentResponse[];
}

export interface ISsTournamentResponse {
  id?: number;
  title?: string;
  in_progress?: boolean;
  user_confirmation_required?: boolean;
  frontend_identifier?: string;
  strategy?: string;
  currencies?: string[];
  game_category_identity?: string;
  start_at?: Date;
  end_at?: Date;
  finished_at?: Date;
  currency?: string;
  money_budget?: string;
  money_budget_cents?: number;
  chargeable_comp_points_budget?: number;
  persistent_comp_points_budget?: number;
  prizes?: any[];
  top_players?: any[];
  chargeable_comp_points_prize_pool?: number;
  persistent_comp_points_prize_pool?: number;
  money_prize_pool?: string;
  money_prize_pool_cents?: number;
  games_taken_limit?: number;
  group_ids?: number[];
}

export const state = () => ({
  tournaments: [] as ISsTournamentResponse[],
});

export const getters: GetterTree<SsTournamentsState, any> = {
  activeTournaments(state: SsTournamentsState): ISsTournamentResponse[] {
    return state.tournaments.filter((t) =>
      filterDateActiveItem({ startDate: t.start_at, finishDate: t.end_at })
    );
  },
};

export const mutations: MutationTree<SsTournamentsState> = {
  SAVE_TOURNAMENTS(state: SsTournamentsState, tournaments: ISsTournamentResponse[]) {
    state.tournaments = tournaments;
  },
};

export const actions: ActionTree<SsTournamentsState, any> = {
  async loadTournaments(ctx: {
    state: any;
    dispatch: any;
    commit: any;
    getters: { shouldLoadPlayerInfo: boolean };
    rootGetters: any;
  }) {
    const lang = ctx.rootGetters['language/language'];
    const tournaments = await loadTournaments(lang);

    ctx.commit('SAVE_TOURNAMENTS', tournaments);
  },
};
