import { GetterTree, MutationTree } from 'vuex';

import { StrapiImageInterface } from '@/types/strapiImage.interface';
import { filterDateActiveItem } from '@/utils/filters';

export enum Enum_Componentbonusesbonustype_Bonustype {
  Cashback = 'Cashback',
  Event = 'Event',
  Exclusive = 'Exclusive',
  Reload = 'Reload',
  Telegram = 'Telegram',
  Vip = 'VIP',
  Welcome = 'Welcome',
}

export const namespaced = true;

export type BonusesState = ReturnType<typeof state>;

export interface BonusesGettersInterface {
  bonuses: BonusInterface[];
  welcomePackBonuses: BonusInterface[];
  promotionBonuses: BonusInterface[];
}

export interface BonusInterface {
  id: number;
  bonusId: string;
  title: string;
  position: number;
  locale: string;
  offer: string;
  bonusType: Enum_Componentbonusesbonustype_Bonustype;
  bannerCardFrontDesktop: StrapiImageInterface;
  bannerCardFrontMobile: StrapiImageInterface;
  groupFilter?: {
    includedGroups?: string | null;
  };
  finishDate?: Date | null;
  bannerCardBack: StrapiImageInterface;
  bannerCardFront: StrapiImageInterface;
  labelColor?: string;
  labelText: string;
}

// export interface BonusResponseWithTabs extends BonusEntityResponseCollection {
//   bonusesTabs: BonusesTabEntityResponseCollection;
// }

export const state = () => ({
  bonuses: [] as BonusInterface[],
});

export const getters: GetterTree<BonusesState, any> = {
  bonuses(state: BonusesState): BonusInterface[] {
    return state.bonuses;
  },
  welcomePackBonuses(state: BonusesState): BonusInterface[] {
    return state.bonuses.filter(
      (bonus) => bonus.bonusType === Enum_Componentbonusesbonustype_Bonustype.Welcome
    );
  },
  promotionBonuses(state: BonusesState): BonusInterface[] {
    return state.bonuses
      .filter((bonus) => bonus.bonusType !== Enum_Componentbonusesbonustype_Bonustype.Welcome)
      .filter(filterDateActiveItem)
      .filter((bonus) => !bonus.groupFilter?.includedGroups);
  },
};

export const mutations: MutationTree<BonusesState> = {
  SAVE_DATA_FROM_STRAPI(state: BonusesState, { data, bonusesTabs }) {
    // imgUrlReplacer(data);
    state.bonuses = data
      .map((bonus) => ({
        id: Number(bonus.id),
        bonusId: bonus.bonusId,
        title: bonus.title,
        position: +bonus.position,
        locale: bonus.locale,
        offer: bonus.offer,
        bonusType: bonus.bonusType.bonusType,
        bannerCardFrontDesktop: bonus.bannerCardFrontDesktop,
        bannerCardFrontMobile: bonus.bannerCardFrontMobile,
        groupFilter: bonus.groupFilter,
        finishDate: bonus.finishDate,
        bannerCardBack: bonus.bannerCardBack,
        bannerCardFront: bonus.bannerCardFront,
        // labelColor: bonusesTabs.data.find(
        //   (el) => el.bonusType.bonusType === bonus.bonusType.bonusType
        // )?.labelColor,
        labelText: bonus.customLabelText || bonus.bonusType.bonusType,
      }))
      .sort((a, b) => a.position - b.position);
  },
};
