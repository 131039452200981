import { GetterTree, MutationTree } from 'vuex';

export const namespaced = true;

export type ComponentsState = ReturnType<typeof state>;

export interface ComponentsGettersInterface {
  timerData: {
    leftTitle: string;
    daysTitle: string;
    hoursTitle: string;
    minutesTitle: string;
    secondsTitle: string;
  };
  racesData: {
    raceLabelWeeklyTitle: string;
    raceLabelDailyTitle: string;
    raceLabelExclusiveTitle: string;
    raceLabelPartnerTitle: string;
  };
}

export const state = () => ({
  timer: {
    leftTitle: '',
    daysTitle: '',
    hoursTitle: '',
    minutesTitle: '',
    secondsTitle: '',
  },
  races: {
    raceLabelWeeklyTitle: '',
    raceLabelDailyTitle: '',
    raceLabelExclusiveTitle: '',
    raceLabelPartnerTitle: '',
  },
  requestPromise: null as Promise<any> | null,
});

export const getters: GetterTree<ComponentsState, any> = {
  timerData(state: ComponentsState) {
    return state.timer;
  },
  racesData(state: ComponentsState) {
    return state.races;
  },
};

export const mutations: MutationTree<ComponentsState> = {
  SAVE_DATA_FROM_STRAPI(state: ComponentsState, { data }) {
    state.races = data.races;
    state.timer = data.timer;
  },
};
