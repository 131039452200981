import { GetterTree, MutationTree } from 'vuex';
import imgUrlReplacer from '@/utils/imgUrlReplacer';

export const namespaced = true;

export interface LandingPageInterface {
  filterCountries: any;
  filterStags: any;
  head: any;
  path: string;
  theme: string;
  template: any;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  groupToAddOnReg: string;
}

export const state = () => ({
  pages: [] as LandingPageInterface[],
});

export type LandingState = ReturnType<typeof state>;

export interface LandingGettersInterface {
  pathes: string[];
  pageData: LandingPageInterface[];
  isLoaded: boolean;
}

export const getters: GetterTree<LandingState, any> = {
  pathes(state): string[] {
    return state.pages.flatMap(({ path }) => path).filter((p) => p);
  },

  pageData(state): LandingPageInterface[] {
    return state.pages;
  },

  isLoaded(_state, getters: LandingGettersInterface): boolean {
    return !!getters.pathes.length;
  },
};

export const mutations: MutationTree<LandingState> = {
  ADD_PAGES_FROM_STRAPI(state, data) {
    imgUrlReplacer(data);

    const newPages = data.map((p) => ({
      path: p.path,
      theme: p.theme,
      groupToAddOnReg: p.groupToAddOnReg || '',
      head: p.head,
      filterCountries: p.filterCountries,
      filterStags: p.filterStags,
      createdAt: new Date(p.createdAt),
      updatedAt: new Date(p.updatedAt),
      publishedAt: new Date(p.publishedAt),
      template: p.template[0],
    }));

    state.pages = newPages;
  },
};
