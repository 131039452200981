import { GetterTree, MutationTree } from 'vuex';
import { StrapiImageInterface } from '@/types/strapiImage.interface';
import { filterDateActiveItem } from '@/utils/filters';

export const namespaced = true;

export type RacesState = ReturnType<typeof state>;

export enum Enum_Race_Racelabel {
  Daily = 'Daily',
  Exclusive = 'Exclusive',
  Partner = 'Partner',
  Weekly = 'Weekly',
}
export interface RacesGettersInterface {
  activeRaces: RaceInterface[];
}

export interface RaceInterface {
  id: number;
  title: string;
  prizeTitle: string;
  raceLabel: Enum_Race_Racelabel;
  bannerSmall: StrapiImageInterface;
  bannerSmallMobile: StrapiImageInterface;
  position: number;
  ssId: string;
  startDate: Date | null;
  finishDate: Date | null;
  locale: string;
  groupFilter?: {
    includedGroups?: string | null;
  };
  bannerCardFront: StrapiImageInterface;
  bannerCardBack: StrapiImageInterface;
}

export const state = () => ({
  races: [] as RaceInterface[],
});

export const getters: GetterTree<RacesState, any> = {
  activeRaces(state: RacesState): RaceInterface[] {
    return state.races
      .filter(filterDateActiveItem)
      .filter((race) => !race.groupFilter?.includedGroups);
  },
};

export const mutations: MutationTree<RacesState> = {
  SAVE_DATA_FROM_STRAPI(state: RacesState, data: any) {
    state.races = data
      .map((race) => ({
        id: Number(race.id),
        title: race.title,
        prizeTitle: race.prizeTitle,
        raceLabel: race.raceLabel,
        bannerSmall: race.bannerSmall,
        bannerSmallMobile: race.bannerSmallMobile,
        bannerCardFront: race.bannerCardFront,
        bannerCardBack: race.bannerCardBack,
        position: +race.position,
        ssId: race.ssId,
        startDate: race.startDate ? new Date(race.startDate) : null,
        finishDate: race.finishDate ? new Date(race.finishDate) : null,
        locale: race.locale,
        groupFilter: race.groupFilter,
      }))
      .sort((a, b) => a.position - b.position);
  },
};
