import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { StrapiImageInterface } from '@/types/strapiImage.interface';
import { imgParser } from '@/utils/imgParser';
import { RootState } from '~/store';

export interface CurrencyMenuItemInterface {
  crypto: boolean; // null;
  currencyCode: string; // "EUR";
  currencySymbol: string; // "€";
  id: number; // 1;
  position: number; // 1;
  stageOnly: boolean; // false
  defaultForCountries: string[]; // ["IT", "DE"]
  exludedCountries: string[]; // ["IT", "DE"]
  precision: number; // 2
  currencyCodeShort?: string; // "mBTC"
  precisionShort?: number; // 4
  multiplierShort?: number; // 1000
  currencyIcon: StrapiImageInterface;
  availableAmounts?: string; // '20,50,100,200,500'
  preselectedAmount?: number; // 20

  createdAt: Date; // "2020-10-12T11:56:13.101Z";
  updatedAt: Date; // "2020-10-12T11:56:22.509Z";
  publishedAt: Date; // "2020-10-12T11:56:21.541Z";
}

export const namespaced = true;

export const state = () => ({
  currencies: [] as CurrencyMenuItemInterface[],
  requestPromise: null as Promise<any> | null,
});

export type CurrencyMenuState = ReturnType<typeof state>;

export interface CurrencyMenuGettersInterface {
  currencies: CurrencyMenuItemInterface[];
  cryptoCurrencyCodes: string[];
}

export const getters: GetterTree<CurrencyMenuState, any> = {
  currencies(state: CurrencyMenuState): CurrencyMenuItemInterface[] {
    return state.currencies.sort((a, b) => a.position - b.position);
  },

  cryptoCurrencyCodes(_state: CurrencyMenuState, getters: CurrencyMenuGettersInterface): string[] {
    return getters.currencies.filter((c) => c.crypto).map((c) => c.currencyCode);
  },
};

export const mutations: MutationTree<CurrencyMenuState> = {
  SAVE_CURRENCIES_FROM_STRAPI(state: CurrencyMenuState, data: any) {
    state.currencies = data
      .map((c) => ({
        id: Number(c.id),
        crypto: !!c.crypto,
        currencyCode: c.currencyCode,
        currencySymbol: c.currencySymbol,
        position: Number(c.position),
        stageOnly: !!c.stageOnly,
        defaultForCountries: (c.countriesDefault || '')
          .split(',')
          .filter((code: string | null) => !!code)
          .map((code) => String(code).toUpperCase()),
        exludedCountries: (c.countriesExcluded || '')
          .split(',')
          .filter((code: string | null) => !!code)
          .map((code) => String(code).toUpperCase()),
        precision: Number(c.position),
        currencyCodeShort: c.currencyCodeShort,
        currencyIcon: imgParser(c.currencyIcon),
        availableAmounts: c.availableAmounts,
        preselectedAmount: Number(c.preselectedAmount),

        createdAt: new Date(c.createdAt),
        updatedAt: new Date(c.updatedAt),
        publishedAt: new Date(c.publishedAt),
      }))
      .sort((a, b) => a.position - b.position);
  },
};

export const actions: ActionTree<CurrencyMenuState, RootState> = {
  isCryptoCurrency(ctx, currencyCode: string) {
    return !!ctx.getters.cryptoCurrencyCodes.includes(currencyCode);
  },
};
